import React from "react";
import Layout from "../components/Layout";
import EducationContactform from "../components/educationcontactcomponent";
import EngagementEducationSection from "../components/Engagementeducationcomponent";
import PedalogyEducationSection from "../components/pedalogysectioncomponent";
import DetailStartXSection from "../components/DetailStartXcomponent";
import EducationVideo from "../components/EducationVideo";
import EducationCourseBenifitsComponent from "../components/EducationCourseBenifitsComponent";
import EducationTopSkillsComponent from "../components/EducationTopSkillsComponent";
import EducationWhatIfComponent from "../components/EducationWhatIfComponent";
import EducationGatewayComponent from "../components/EducationGatewayComponent";

import { useEffect } from "react";

const Educationpage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Layout>
        <EducationGatewayComponent />
        <DetailStartXSection />
        <EducationTopSkillsComponent />
        <EducationCourseBenifitsComponent />
        <EducationVideo />
        <EducationWhatIfComponent />
        <PedalogyEducationSection />
        <EngagementEducationSection />
        <EducationContactform />
      </Layout>
    </>
  );
};

export default Educationpage;
