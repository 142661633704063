import React, { useState } from "react";
import "../style/educationgateway.scss";
import EducationModal from "./EducationModalForm";
import { Helmet } from "react-helmet-async";

const EducationGatewayComponent = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Dive into the educational resources provided by Immverse AI. Explore our avatars, technology, and the future of digital interaction.
          "
        />
        <meta
          name="keywords"
          content="Immverse, Immverse AI, ImmverseAI,Artificial Intelligence, AI, Personalized AI, Immortalizing
        Humans, Human Preservation, Digital Avatar, Virtual Presence"
        />
        <meta name="author" content="Immverse AI" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.immverseai.ai/" />
        <meta
          property="og:title"
          content="Immverse AI | Immortalizing Humanity through AI"
        />
        <meta property="og:image" content="/immverseLogo.svg" />
        <meta property="twitter:card" content="/immverseLogo.svg" />
        <meta property="twitter:url" content="https://www.immverseai.ai/" />
        <meta
          property="twitter:title"
          content="ImmverseAI - Welcome To ImmverseAI"
        />
        <meta
          property="twitter:description"
          content="Dive into the educational resources provided by Immverse AI. Explore our avatars, technology, and the future of digital interaction.
          "
        />
        <title>ImmverseAI -Welcome to ImmverseAI</title>
      </Helmet>
      <div className="relative w-full items-center justify-center mt-8 sm:!mt-8 lg:!mt-0 ">
        <div className="absolute top-4  sm:!top-0 left-0 right-0 bottom-0 ">
          <div className="flex flex-col md:w-[40%] lg:w-[70%] w-[90%] pt-0  pl-8 sm:pl-16 md:pl-[4rem] xl:pl-[10rem] lg:pl-[7rem]">
            <p className="md:gap-1 w-full flex flex-col  justify-center md:justify-normal text-white text-3xl sm:text-3xl lg:text-[45px] text-[40px] font-semibold sm:pt-12 pt-6 sm:-mr-36 gap-4 ">
              <div className="flex gap-8 pt-12 lg:pt-24">
                <span className="p-2 md:w-44 md:h-[3rem] xl:h-[4.5rem] h-12 text-[2rem] sm:text-lg md:text-[2rem] lg:text-5xl lg:h-16 rounded-lg bg-gradient-to-tr from-blue-900 to-pink-600  mb-1 flex sm:h-10 md:mt-4 lg:mt-0">
                  MBA{" "}
                  <div className="">
                    <sup>x</sup>
                  </div>
                </span>{" "}
                {/* <div className="bg-gradient-to-tr from-blue-900 to-pink-600 w-34 xl:w-36 rounded-lg  p-2 mt-2 text-[2rem]">
                  MBA<sup className="z-0 ">x</sup>
                </div> */}
                <div className="text-3xl sm:text-2xl md:text-[30px] xl:text-5xl lg:text-[2.5rem] xl:text-[51.2px]  lg:pt-[3.5rem] -ml-6 w-full pt-2 sm:pt-4 md:mt-[1rem] ">
                  <span className="text-pink-700 ">Embark </span>
                </div>
              </div>
              <div className="w-full text-3xl sm:text-2xl md:text-[30px] lg:text-[2.5rem] xl:text-[51.2px] ml-2 sm:ml-0 md:ml-0 lg:ml-0 -mt-6 md:mt-[-2rem] lg:-mt-2 flex items-center justify-center  md:pl-0  sm:justify-start">
                on a Journey of
              </div>
              <div className="w-full text-3xl sm:text-2xl md:text-[30px] lg:text-[2.5rem] xl:text-[51.2px] ml-2 sm:ml-0 md:ml-0  -mt-6  lg:-mt-2 md:-mt-6 flex items-center justify-center  md:pl-0   sm:justify-start ">
                {" "}
                Innovation
              </div>
            </p>
            <button
              onClick={openModal}
              className="ml-24  hover:scale-110 sm:ml-0 lg:ml-0  md:w-40 lg:w-48 w-28 h-12 sm:px-4 px-2 md:py-[6px] py-[1px] rounded-full justify-center items-center gradient-bg sm:mt-4 mt-16 text-white md:gap-2  flex flex-row text-[10px] md:text-[12px] md:mt-4 lg:mt-8 xl:mt-16 "
            >
              <svg
                width="15"
                height="15"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.72363 10.0001H18.9299M18.9299 10.0001L15.5681 6.62506M18.9299 10.0001L15.5681 13.3751"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1 10C1 7.61305 1.94453 5.32387 3.6258 3.63604C5.30708 1.94821 7.58737 1 9.96505 1M9.96505 19C8.62095 19.0012 7.29383 18.6984 6.08227 18.1142C4.87071 17.5299 3.80583 16.6791 2.96671 15.625"
                  stroke="white"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
              Book a free trial
            </button>
            {/* {isModalOpen && <EducationModal />} */}
            <EducationModal isModalOpen={isModalOpen} closeModal={closeModal} />
          </div>
        </div>
        <img
          className="z-[0] sm:w-[40rem] sm:h-[28rem] sm:pl-40 md:!w-[78%] lg:w-[52rem] xl:w-[63rem] md:pt-10 pt-[20rem] lg:pt-0 md:h-[30rem] lg:h-[36rem] xl:h-[39rem] sm:pt-10 md:ml-[20%] xl:pl-[17rem] h-[39rem] "
          src="/education/student.png"
          loading="lazy"
          alt="Student"
        />
      </div>
    </>
  );
};

export default EducationGatewayComponent;
