import React, { useEffect, useState } from "react";
import ellipse3 from "../assets/About/ellipse3.svg";
import ellipse4 from "../assets/About/ellipse4.svg";
import "../style/blog.scss";
import { Link } from "react-router-dom";
import axios from "axios";
import Contactform from "./Contactform";
import { domainData } from "./Domain";
import { Helmet } from "react-helmet-async";
import Select from "react-select";
import "react-loading-skeleton/dist/skeleton.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import BlogLoadingComponent from "./BlogLoadingComponent";

const Blog = () => {
  const [selectedOption, setSelectedOption] = useState("Select an Option");
  const [message, setMessage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [blogData, setBlogdata] = useState([]);
  const [categories, setCategories] = useState([]);
  const [formdata, setFormData] = useState({
    keyword: "",
    blog: "",
  });
  const [loader, setLoader] = useState(true);
  const [shimmeringImages, setShimmeringImages] = useState([]);

  const toggleOptions = () => {
    setIsOpen(!isOpen);
  };

  const selectOption = (value) => {
    setSelectedOption(value);
    toggleOptions();
  };

  useEffect(() => {
    setShimmeringImages(blogData.map((user) => user.id));
  }, [blogData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formdata,
      [name]: value,
    });
  };

  const options = categories.map((category) => ({
    value: category.id,
    label: category.category,
  }));

  useEffect(() => {
    const getData = async (page = 1) => {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${domainData.url}/get-all-blogs?page=${page}`,
        headers: {
          "x-api-key": "Immverse-API-sam-0987654321",
        },
      };

      try {
        const response = await axios.request(config);
        setBlogdata(response.data.blogs.data);
        setLoader(false);
        setCategories(response.data.categories);
        setCurrentPage(response.data.blogs.current_page);
        setTotalPages(response.data.blogs.last_page);
      } catch (error) {
        console.error(error);
      }
    };

    getData(currentPage);
  }, [currentPage]);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      scrollToTop();
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      scrollToTop();
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };

  const getPaginationNumbers = () => {
    const numbers = [];
    for (let i = 1; i <= totalPages; i++) {
      numbers.push(i);
    }
    return numbers;
  };

  const SearchData = async (e) => {
    e.preventDefault();
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${domainData.url}/get-all-blogs?search=${formdata.keyword}&id=${formdata.blog}`,
      headers: {
        "x-api-key": "Immverse-API-sam-0987654321",
      },
    };

    try {
      const response = await axios.request(config);

      if (response.data.blogs.data.length === 0) {
        setBlogdata([]);
        setMessage("No data found");
      } else {
        setBlogdata(response.data.blogs.data);
        setMessage("");
      }
    } catch (error) {}
  };

  const handleSelect = (selectedOption) => {
    setSelectedCategory(selectedOption ? selectedOption.value : null);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Stay updated with the Immverse AI blog. Explore our latest insights, news, and articles on avatar technology and digital experiences."
        />
        <meta
          name="keywords"
          content="Immverse, Immverse AI, ImmverseAI, Artificial Intelligence, AI, Personalized AI, Immortalizing Humans, Human Preservation, Digital Avatar, Virtual Presence"
        />
        <meta name="author" content="Immverse AI" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.immverseai.ai/" />
        <meta
          property="og:title"
          content="Immverse AI | Immortalizing Humanity through AI"
        />
        <meta property="og:image" content="/immverseLogo.svg" />
        <meta property="twitter:card" content="/immverseLogo.svg" />
        <meta property="twitter:url" content="https://www.immverseai.ai/" />
        <meta
          property="twitter:title"
          content="ImmverseAI - Welcome To ImmverseAI"
        />
        <meta
          property="twitter:description"
          content="Stay updated with the Immverse AI blog. Explore our latest insights, news, and articles on avatar technology and digital experiences."
        />
        <title>ImmverseAI - Blogs</title>
      </Helmet>
      <div
        className="blog-container"
        style={{
          backgroundImage: `url(${ellipse3})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top right",
        }}
      >
        <div className="blog-content">
          <div className="ellipse4">
            <img src={ellipse4} alt="" width="600" loading="lazy" />
          </div>
          <h1>
            <span>Insights </span>& Perspectives
          </h1>
          <p>
            Dive into a world of knowledge and innovation with our
            thought-provoking insights and perspectives. Explore diverse topics,
            gain valuable knowledge, and stay ahead in the ever-evolving
            landscape of ideas.
          </p>
          <form onSubmit={SearchData}>
            <div className="search-blog">
              <input
                className="input1"
                type="text"
                id="keyword"
                name="keyword"
                value={formdata.keyword}
                onChange={handleInputChange}
                placeholder="Enter your keyword"
              />

              <div className="custom-dropdown">
                <Select
                  value={categories.find(
                    (category) => category.value === selectedCategory
                  )}
                  onChange={handleSelect}
                  options={options}
                  placeholder="Select Blog Category"
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      height: "60px",
                      borderRadius: "40px",
                      border: "2px solid #333135",
                      width: "13.5rem",
                      backgroundColor: "rgba(22, 22, 22, 0.2)",
                      outline: "none",
                      boxShadow: state.isFocused ? 0 : 0,
                      color: " #d4d1db",
                      ":hover": {
                        border: "2px solid #333135",
                      },
                      ":focus": {
                        border: "none",
                        outline: "none",
                        boxShadow: "0 0 0 2px #333135",
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      color: state.isSelected ? " #d4d1db" : " #d4d1db",
                      backgroundColor: state.isSelected
                        ? "#333135"
                        : "transparent",
                      ":hover": {
                        backgroundColor: "#333135",
                        color: " #d4d1db",
                      },
                    }),
                    menu: (provided) => ({
                      ...provided,
                      backgroundColor: "rgba(22, 22, 22, 0.5)",
                    }),
                    singleValue: (provided) => ({
                      ...provided,
                      color: " #d4d1db",
                    }),
                    indicatorSeparator: (provided) => ({
                      ...provided,
                      backgroundColor: "transparent",
                    }),
                    indicatorContainer: (provided) => ({
                      ...provided,
                      display: "none",
                    }),
                  }}
                />
              </div>

              <div className="btn-position">
                <button>Search</button>
              </div>
            </div>
          </form>

          {message && <p className="error-message">{message}</p>}

          {loader ? (
            <BlogLoadingComponent />
          ) : (
            <>
              <div className="blogs-wrapper">
                <div className="blogs">
                  {Array.isArray(blogData) &&
                    blogData.length > 0 &&
                    blogData.map((user) => (
                      <Link
                        key={user.id}
                        className="blog-card"
                        to={`/blogdetails/${user.id}`}
                      >
                        {user.main_image && user.main_image.original_url && (
                          <img
                            src={user.main_image.original_url}
                            alt=""
                            style={{ height: "280px", width: "100%" }}
                            loading="lazy"
                          />
                        )}

                        <div className="card-content">
                          <p className="generativeAI p-2">
                            {user.blog_category.category}
                          </p>
                          <h1>{user.blog_title.slice(0, 15)}...</h1>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: user.blog_content.slice(0, 150),
                            }}
                          ></p>
                        </div>
                      </Link>
                    ))}
                </div>
              </div>
              <div className="pagination flex text-white gap-2 justify-center w-100">
                <button
                  onClick={handlePreviousPage}
                  disabled={currentPage === 1} className={`flex items-center gap-1 rounded-full p-2 ${
                    currentPage === 1 ? 'bg-gray-300 text-gray-400' : 'bg-gray-200 text-black'
                  }`}
                >
                  <FaChevronLeft />
                </button>

                {getPaginationNumbers().map((number) => (
                  <button
                    key={number}
                    onClick={() => {
                      setCurrentPage(number);
                      scrollToTop();
                    }}
                    className={`flex items-center gap-1 ${
                      currentPage === number
                        ? "font-bold text-gray-50"
                        : "text-gray-600"
                    }`}
                  >
                    {number}
                  </button>
                ))}

                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  className={`flex items-center gap-1 rounded-full p-2 ${
                    currentPage === totalPages ? 'bg-gray-300 text-gray-400' : 'bg-gray-200 text-black'
                  }`}
                  
                >
                  <FaChevronRight />
                </button>
              </div>
            </>
          )}
          <Contactform />
        </div>
        <hr />
      </div>
    </>
  );
};

export default Blog;
