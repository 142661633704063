import React, { useState, useRef } from "react";

import EducationContactform from "../educationcontactcomponent";
// import video from "../../assets/FellowShip/students-img.png";
import video from "../../assets/FellowShip/Alan-turing.mp4";
import img1 from "../../assets/Education/student.png";
import ellipse3 from "../../assets/About/ellipse3.svg";
import ellipse4 from "../../assets/About/ellipse4.svg";
// import FellowshipForm from "../FellowshipFormComponent";
import FellowshipForm from "./FellowshipAlanTuringFormComponent";
import { useNavigate } from "react-router-dom";
import macbook from "../../assets/About/mackbook.png";
import YouTube from "react-youtube";
import { Helmet } from "react-helmet-async";

const FellowshipComponent = () => {
  const navigate = useNavigate();

  const [hasPlayedVideo, setHasPlayedVideo] = useState(false);

  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [userInteracted, setUserInteracted] = useState(false);
  const handleVideoClick = () => {
    setUserInteracted(true);

    if (!isPlaying) {
      videoRef.current.play().catch((error) => {
        console.error("Failed to play the video:", error.message);
      });
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openFellowShip = () => {
    navigate(`./apply/Alan-Turing-fellowship`);
  };

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Join the Immverse AI fellowship program and become part of our community. Collaborate with innovators shaping the future of avatar technology.
          "
        />
        <meta
          name="keywords"
          content="Immverse, Immverse AI, ImmverseAI,Artificial Intelligence, AI, Personalized AI, Immortalizing
        Humans, Human Preservation, Digital Avatar, Virtual Presence"
        />
        <meta name="author" content="Immverse AI" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.immverseai.ai/" />
        <meta
          property="og:title"
          content="Immverse AI | Immortalizing Humanity through AI"
        />
        <meta property="og:image" content="/immverseLogo.svg" />
        <meta property="twitter:card" content="/immverseLogo.svg" />
        <meta property="twitter:url" content="https://www.immverseai.ai/" />
        <meta
          property="twitter:title"
          content="ImmverseAI - Welcome To ImmverseAI"
        />
        <meta
          property="twitter:description"
          content="Join the Immverse AI fellowship program and become part of our community. Collaborate with innovators shaping the future of avatar technology.
          "
        />
        <title>ImmverseAI -Welcome to ImmverseAI</title>
      </Helmet>
      <>
        <div className="w-full flex justify-center items-center mt-0 sm:!mt-12 xl:!mt-24 sm:mb-2 md:!mt-16 md:mb-2 xl:mb-40">
          <div className="w-4/5 flex-col flex sm:flex-row-reverse gap-4 mt-40 mb-8 sm:!mb-0 sm:!mt-12 md:!mt-20 lg:!mt-12 xl:!mt-4">
            <div className="w-full   sm:w-1/2">
              <div className="flex flex-1 flex-column">
                <img
                  src={macbook}
                  className="relative top-96  sm:!top-0 right-[0.8rem]  sm:mr-0 ml-8 sm:!ml-0"
                />

                <video
                  ref={videoRef}
                  width="100%"
                  // className="h-[9rem] left-[16%] top-[31rem] w-[62%] absolute sm:!h-[10rem]  sm:!top-[6rem] sm:left-[52%] sm:w-[31%] md:!top-[9rem] md:!w-[32%] md:!h-[11rem] md:left-[52%] lg:w-[32%] lg:!h-[12rem] lg:!left-[52%] lg:!top-[9rem]  xl:w-[32%] xl:!h-[16rem] xl:!left-[52%] xl:!top-[8rem]   2xl:w-[30%] 2xl:!h-[19rem] 2xl:left-[14%] 2xl:!top-[8rem]"
                  height="100%"
                  className="absolute top-[35.5rem] w-[54%] left-[24%] sm:w-[30%]  sm:left-[54%] sm:top-[7.5rem] md:w-[30%]  md:top-[10.5rem] lg:top-[9.5rem] lg:left-[54.5%] xl:top-[] "
                  controls
                  autoPlay={false}
                  onClick={handleVideoClick}
                  loading="lazy"
                >
                  <source src={video} type="video/mp4" />
                  Your browser does not support the video
                </video>
              </div>
            </div>
            <div className="text-[#c3c1c7] w-full sm:w-1/2  mt-[-17rem] sm:mt-0 md:!mt-[-1rem] lg:!mt-0  xl:mt-[1rem]">
              <h1 className="text-[1.7rem] sm:text-3xl md:text-[1.6rem] lg:text-4xl xl:text-5xl">
                <span className="text-pink-700  font-bold">
                  Alan Turing&nbsp;
                </span>
                AI Fellowship
              </h1>
              <p className="pt-8 sm:pt-0 md:pt-0 text-sm sm:text-[8px] md:text-[9px] lg:text-[12px] xl:text-sm xl:pt-2 ">
                If you've built some cool stuff in the AI/ML space and want to
                shout it out to the world, then you should be part of my
                one-year cohort called the Alan Turing AI Fellowship. Sign up,
                and I will see you there with a team of 50+ AI/ML tech
                enthusiasts who are building bleeding-edge tech and are eager to
                welcome you.
              </p>

              <p className=" rounded-xl text-[#c3c1c7] mt-4 text-sm sm:text-[8px] lg:text-[10px] xl:text-[14px] font-bold  p-2">
                <h1 className="text-pink-700 font-bold">Who?</h1>
                Demonstrate 6+ months of hustling and building some cool stuff
                in the realm of AI/ML.
              </p>
              <button
                onClick={openFellowShip}
                className="  bg-gradient-to-tr from-blue-900 to-pink-600 hover:scale-110   text-[#c3c1c7] rounded-3xl p-2 w-32 sm:w-28  lg:w-32 mt-4 sm:!mt-0 md:!mt-0 lg:!mt-0 xl:!mt-2 ml-[5.5rem] sm:ml-0"
              >
                Apply Now
              </button>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default FellowshipComponent;
