import React from "react";
import Layout from "../components/Layout";

import { useEffect } from "react";
import FellowshipComponent from "../components/FellowShip/FellowshipComponent";
import EducationContactform from "../components/educationcontactcomponent";
import FellowShipBenefits from "../components/FellowShip/FellowshipBenefitsComponent";
import FellowshipAboutComponent from "../components/FellowShip/FellowShipAboutComponent";
import FellowshipEligibilityComponent from "../components/FellowShip/FellowshipEligibilityComponent";
import FellowshipSelectionComponent from "../components/FellowShip/FellowshipSelectionComponent";

const FellowshipPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Layout>
        <FellowshipComponent />
        <FellowshipAboutComponent />
        <FellowshipEligibilityComponent />
        <FellowShipBenefits />

        <FellowshipSelectionComponent />
        <EducationContactform />
      </Layout>
    </>
  );
};

export default FellowshipPage;
