import React from 'react'
import { useEffect } from 'react';
import Layout from './../components/Layout';
import Blog from '../components/Blog';
import PrivacyPolicy from './../components/PrivacyPolicy';

const PrivacyPolicyPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Layout>
            <PrivacyPolicy />
        </Layout>
    )
}

export default PrivacyPolicyPage