import React from "react";
import "../style/privacypolicy.scss";
import { Helmet } from "react-helmet-async";
import Contactform from './Contactform';
import PrivacyPolicyDetails from "./PrivacyPolicyDetails";

const PrivacyPolicy = () => {
    return (
        <>
            <Helmet>
                <meta
                    name="description"
                    content="Immverse AI is on a mission to immortalize humans by harnessing the power of advanced
        artificial intelligence. We create personalized AI representations, preserving and embodying individual human
        personalities for eternity."
                />
                <meta
                    name="keywords"
                    content="Immverse, Immverse AI, ImmverseAI,Artificial Intelligence, AI, Personalized AI, Immortalizing
        Humans, Human Preservation, Digital Avatar, Virtual Presence"
                />
                <meta name="author" content="Immverse AI" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.immverseai.ai/" />
                <meta
                    property="og:title"
                    content="Immverse AI | Immortalizing Humanity through AI"
                />
                <meta property="og:image" content="/immverseLogo.svg" />
                <meta property="twitter:card" content="/immverseLogo.svg" />
                <meta property="twitter:url" content="https://www.immverseai.ai/" />
                <meta
                    property="twitter:title"
                    content="ImmverseAI - Welcome To ImmverseAI"
                />
                <meta
                    property="twitter:description"
                    content="Immverse AI is on a mission to immortalize humans by harnessing the power of advanced
        artificial intelligence. We create personalized AI representations, preserving and embodying individual human
        personalities for eternity."
                />
                <title>ImmverseAI - Privacy Policy</title>
            </Helmet>
            <div className="privacy-policy-container">
                <div className="privacy-policy-container">
                    <div className="privacy-policy-content">
                        <h1>
                            <span>Privacy </span>Policy
                        </h1>
                        <PrivacyPolicyDetails />
                        <Contactform />
                    </div>
                </div>
                <hr />
            </div>
        </>
    );
};

export default PrivacyPolicy;
