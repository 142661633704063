import React from "react";
import axios from "axios";
import { useState } from "react";
import "../style/contactform.scss";
import { domainData } from "./Domain";
import { Helmet } from "react-helmet-async";

const Contactform = () => {
  const [formdata, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formdata,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (
      !formdata.name ||
      !formdata.email ||
      !formdata.phone ||
      !formdata.message
    ) {
      alert("Please fill in all the required fields.");
      return;
    }
  
    const phonePattern = /^\d+$/;
    if (!phonePattern.test(formdata.phone)) {
      alert("Please enter a valid phone number (digits only).");
      return;
    }
  
    if (formdata.phone.length < 10 || formdata.phone.length > 15) {
      alert(`Phone number should be between 10 and 15 digits.`);
      return;
    }
  
    // Email validation regex
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formdata.email)) {
      alert("Please enter a valid email address.");
      return;
    }
  
    let data = {
      name: formdata.name,
      email: formdata.email,
      phone: formdata.phone,
      message: formdata.message,
    };
  
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${domainData.url}/save-contact-request`,
      headers: {
        "X-Api-Key": "Immverse-API-sam-0987654321",
      },
      data: data,
    };
  
    await axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        if (response.data.msg) {
          alert(
            "Thank you for submitting details. Our Team will get back to you shortly."
          );
        }
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };
  

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Reach out to Immverse AI for inquiries, collaborations, or questions. Get in touch with our team to explore opportunities in avatar technology.
          "
        />
        <meta
          name="keywords"
          content="Immverse, Immverse AI, ImmverseAI,Artificial Intelligence, AI, Personalized AI, Immortalizing
        Humans, Human Preservation, Digital Avatar, Virtual Presence"
        />
        <meta name="author" content="Immverse AI" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.immverseai.ai/" />
        <meta
          property="og:title"
          content="Immverse AI | Immortalizing Humanity through AI"
        />
        <meta property="og:image" content="/immverseLogo.svg" />
        <meta property="twitter:card" content="/immverseLogo.svg" />
        <meta property="twitter:url" content="https://www.immverseai.ai/" />
        <meta
          property="twitter:title"
          content="ImmverseAI - Welcome To ImmverseAI"
        />
        <meta
          property="twitter:description"
          content="Reach out to Immverse AI for inquiries, collaborations, or questions. Get in touch with our team to explore opportunities in avatar technology.
          "
        />
        <title>ImmverseAI -Welcome to ImmverseAI</title>
      </Helmet>
      <div className="getInTouch-section">
        <div className="address">
          <h1>
            <span>Get in touch</span> <br></br> with us
          </h1>
          <p>
            We have our presence in the heart of India & Silicon Valley, USA.
            Reach out to us to dive into the realm of immortalization and shape
            the course of eternity.
          </p>
          <div className="location-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              // width="20"
              // height="25"
              // viewBox="0 0 27 32"
              width="40"
              height="22"
              viewBox="0 0 27 32"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.92822 13.5069C5.92822 11.5623 6.71804 9.69738 8.12392 8.32234C9.5298 6.94729 11.4366 6.1748 13.4248 6.1748C15.413 6.1748 17.3198 6.94729 18.7257 8.32234C20.1316 9.69738 20.9214 11.5623 20.9214 13.5069C20.9214 15.4515 20.1316 17.3165 18.7257 18.6915C17.3198 20.0666 15.413 20.8391 13.4248 20.8391C11.4366 20.8391 9.5298 20.0666 8.12392 18.6915C6.71804 17.3165 5.92822 15.4515 5.92822 13.5069ZM13.4248 8.49021C12.0644 8.49021 10.7598 9.01876 9.79788 9.95958C8.83596 10.9004 8.29556 12.1764 8.29556 13.5069C8.29556 14.8374 8.83596 16.1135 9.79788 17.0543C10.7598 17.9951 12.0644 18.5237 13.4248 18.5237C14.7852 18.5237 16.0898 17.9951 17.0517 17.0543C18.0136 16.1135 18.554 14.8374 18.554 13.5069C18.554 12.1764 18.0136 10.9004 17.0517 9.95958C16.0898 9.01876 14.7852 8.49021 13.4248 8.49021Z"
                fill="#D71D89"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0.0477189 11.7422C0.317479 8.54201 1.8078 5.55805 4.22278 3.38277C6.63776 1.2075 9.80079 -9.77963e-06 13.0839 0H13.7657C17.0487 -9.77963e-06 20.2118 1.2075 22.6268 3.38277C25.0417 5.55805 26.5321 8.54201 26.8018 11.7422C27.102 15.3068 25.9761 18.8463 23.6611 21.6151L16.0967 30.6622C15.773 31.0494 15.3653 31.3614 14.9032 31.5756C14.4411 31.7899 13.9362 31.901 13.4248 31.901C12.9134 31.901 12.4084 31.7899 11.9463 31.5756C11.4842 31.3614 11.0766 31.0494 10.7528 30.6622L3.18839 21.6151C0.873315 18.8464 -0.252641 15.3069 0.0477189 11.7422ZM13.0839 2.31541C10.3953 2.31581 7.80523 3.3049 5.82769 5.08637C3.85015 6.86783 2.62975 9.31141 2.40875 11.9321C2.15875 14.8987 3.09572 17.8444 5.02229 20.1487L12.5867 29.1973C12.6884 29.319 12.8165 29.4171 12.9617 29.4845C13.1069 29.5518 13.2656 29.5868 13.4263 29.5868C13.5871 29.5868 13.7458 29.5518 13.891 29.4845C14.0362 29.4171 14.1643 29.319 14.266 29.1973L21.8304 20.1487C23.7558 17.8439 24.6917 14.8983 24.4408 11.9321C24.2198 9.31115 22.9991 6.86735 21.0213 5.08584C19.0434 3.30434 16.4529 2.31542 13.7641 2.31541H13.0823H13.0839Z"
                fill="#D71D89"
              />
            </svg>
            {/* <p> <span style={{ color: '#d71d89' ,marginLeft:"-5px"}}>India :</span> Nagpur, Pune, Delhi  | <span style={{ color: '#d71d89' }}>USA :</span> San Francisco (California) </p> */}

            <p>
              <span style={{ color: "#d71d89", marginLeft: "-5px" }}>
                India :{" "}
              </span>
              Nagpur, Pune, Delhi<br></br>
              <span style={{ color: "#d71d89", marginLeft: "-5px" }}>
                USA :{" "}
              </span>
              San Francisco (California)
            </p>
          </div>
          <div className="location">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="17"
              viewBox="0 0 31 25"
              fill="none"
            >
              <path
                d="M1 4.90264C1 4.08386 1.33255 3.29862 1.9245 2.71965C2.51645 2.14069 3.31931 1.81543 4.15645 1.81543H26.2516C27.0888 1.81543 27.8916 2.14069 28.4836 2.71965C29.0755 3.29862 29.4081 4.08386 29.4081 4.90264V20.3387C29.4081 21.1575 29.0755 21.9427 28.4836 22.5217C27.8916 23.1007 27.0888 23.4259 26.2516 23.4259H4.15645C3.31931 23.4259 2.51645 23.1007 1.9245 22.5217C1.33255 21.9427 1 21.1575 1 20.3387V4.90264Z"
                stroke="#D71D89"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M1 4.90283L15.204 14.1645L29.4081 4.90283"
                stroke="#D71D89"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <p>hello@immverse.ai</p>
          </div>
        </div>
        <form className="form" onSubmit={handleSubmit} id="contact-form">
          <div className="nameEmail flex flex-col">
            <div className="flex gap-2">
              <div className="input-box">
                <label htmlFor="name">Name*</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={formdata.name}
                  onChange={handleInputChange}
                  placeholder="Name"
                  autoComplete="name"
                />
              </div>
              <div className="input-box">
                <label htmlFor="email">Email*</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formdata.email}
                  onChange={handleInputChange}
                  placeholder="Email"
                  autoComplete="email"
                />
              </div>
            </div>

            <div className="input-box">
              <label htmlFor="phone">Mobile No*</label>
              <input
                type="phone"
                name="phone"
                id="phone"
                value={formdata.phone}
                onChange={handleInputChange}
                placeholder="Enter your mobile no."
                autoComplete="phone"
              />
            </div>
          </div>

          <div className="textarea-box">
            <label htmlFor="message"> Your Message*</label>
            <textarea
              id="message"
              name="message"
              rows="3"
              cols="33"
              placeholder="Enter your message"
              value={formdata.message}
              onChange={handleInputChange}
            ></textarea>
          </div>
          <div className="btn-position">
            <button type="submit">
              Send
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 20 20"
                fill="none"
              >
                <path
                  d="M18.3383 7.31637L4.33904 0.314359C3.78656 0.0392893 3.16272 -0.0586374 2.55257 0.0339303C1.94242 0.126498 1.37566 0.405055 0.929552 0.831623C0.483448 1.25819 0.179714 1.81201 0.0597603 2.41757C-0.0601932 3.02313 0.00947168 3.65097 0.259257 4.21548L2.65913 9.58702C2.71358 9.71689 2.74163 9.85631 2.74163 9.99714C2.74163 10.138 2.71358 10.2774 2.65913 10.4073L0.259257 15.7788C0.0559679 16.2356 -0.0299718 16.7361 0.00924744 17.2346C0.0484667 17.7331 0.211602 18.2139 0.483826 18.6333C0.756051 19.0527 1.12874 19.3974 1.56801 19.6361C2.00728 19.8748 2.49922 19.9999 2.99911 20C3.46731 19.9953 3.92855 19.886 4.34903 19.6799L18.3483 12.6779C18.8448 12.428 19.2622 12.045 19.5539 11.5717C19.8456 11.0983 20 10.5532 20 9.99714C20 9.44108 19.8456 8.89597 19.5539 8.4226C19.2622 7.94924 18.8448 7.56625 18.3483 7.31637H18.3383ZM17.4483 10.8874L3.44908 17.8894C3.26526 17.9777 3.05884 18.0077 2.8575 17.9753C2.65617 17.9429 2.46955 17.8497 2.32266 17.7082C2.17577 17.5667 2.07564 17.3837 2.03569 17.1836C1.99574 16.9836 2.01789 16.7761 2.09916 16.589L4.48903 11.2175C4.51996 11.1458 4.54668 11.0723 4.56902 10.9974H11.4586C11.7238 10.9974 11.9782 10.892 12.1657 10.7044C12.3532 10.5169 12.4586 10.2624 12.4586 9.99714C12.4586 9.73184 12.3532 9.47742 12.1657 9.28983C11.9782 9.10223 11.7238 8.99685 11.4586 8.99685H4.56902C4.54668 8.92199 4.51996 8.84851 4.48903 8.77678L2.09916 3.40524C2.01789 3.21815 1.99574 3.01068 2.03569 2.81064C2.07564 2.61061 2.17577 2.42757 2.32266 2.28607C2.46955 2.14458 2.65617 2.05139 2.8575 2.019C3.05884 1.98661 3.26526 2.01658 3.44908 2.10487L17.4483 9.10688C17.6121 9.19082 17.7496 9.31835 17.8456 9.47543C17.9415 9.63251 17.9923 9.81304 17.9923 9.99714C17.9923 10.1812 17.9415 10.3618 17.8456 10.5188C17.7496 10.6759 17.6121 10.8034 17.4483 10.8874Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Contactform;
