import img1 from "../assets/image4.png";
import img2 from "../assets/image1.png";
import img3 from "../assets/image3.png";
import img4 from "../assets/image2.png";
import ellipse3 from "../assets/About/ellipse3.svg";
import ellipse4 from "../assets/About/ellipse4.svg";

const DetailStartXSection = () => {
  return (
    <>
      <div className="main-container-startx w-full flex flex-col sm:flex-row justify-center items-center sm:pt-4 md:pt-24 pt-8">
        <div className="ellipse2 absolute top-0 right-0  z-[-1]">
          <img src={ellipse3} alt="" className="w-90" />
        </div>

        <div className="ellipse absolute top-0 left-0  z-[-1] ">
          <img src={ellipse4} alt="" className="w-90" />
        </div>

        <div className="StartX-container w-4/5 flex flex-col-reverse pt-20 sm:pt-20 md:pt-0 md:flex-row justify-center lg:gap-20 xl:gap-12">
          <div className="image-section-education gap-2 sm:gap-4  lg:gap-6   pt-8 sm:pt-0 sm:ml-[3rem] md:ml-0 flex  flex-row w-[40%] md:w-1/4 lg:w-2/4">
            <div className="twoimg flex flex-col w-1/4 mt-20 gap-8 lg:ml-10 ">
              <img
                src={img1}
                className="min-w-[8rem] h-32 sm:h-56 sm:min-w-[14rem] md:min-w-[9rem] md:h-36 lg:min-w-[12rem] lg:h-48 xl:min-w-[14rem] xl:h-56 rounded-xl"
              ></img>
              <img
                src={img3}
                className="min-w-[8rem] h-32 sm:h-56 sm:min-w-[14rem] md:min-w-[9rem] md:h-36 lg:min-w-[12rem] lg:h-48 xl:min-w-[14rem] xl:h-56 rounded-xl"
              ></img>
            </div>

            <div className="twoimgsect flex flex-col w-1/4 mt-30 gap-4 ml-[7.5rem] sm:ml-[11.5rem] md:ml-[6.5rem] rounded-xl">
              <img
                src={img4}
                className="min-w-[8rem] h-32 sm:h-56 sm:min-w-[14rem] md:min-w-[9rem] md:h-36 lg:min-w-[12rem] lg:h-48 xl:min-w-[14rem] xl:h-56 rounded-xl"
              ></img>
              <img
                src={img2}
                className="min-w-[8rem] h-32 sm:h-56 sm:min-w-[14rem] md:min-w-[9rem] md:h-36 lg:min-w-[12rem] lg:h-48 xl:min-w-[14rem] xl:h-56 rounded-xl"
              ></img>
            </div>
          </div>

          <div className="text-section lg:-ml-4 lg:mt-[5rem] md:mt-[3rem] md:ml-44 -full sm:mt-100">
            <h1 className="text-white text-3xl md:text-3xl lg:text-5xl xl:text-5xl font-bold mb-4 pb-4">
              <span className="text-[#d71d89] mb-4">What </span>is
              <div className="flex gap-8 ">
                <div className="bg-gradient-to-tr from-blue-900 to-pink-600 w-34 xl:w-36 rounded-lg  p-2 mt-2 z-[-1]">
                  MBA<sup className="z-0 ">x</sup>
                </div>
                <span className="pt-2 -ml-6"> Program ?</span>
              </div>
            </h1>
            <p className="w-full md:w-[90%] md:text-sm text-[white] text-left">
              The MBAx programs meticulously curated for adolescents are poised
              to cultivate creativity, instill confidence, and nurture business
              acumen. Immverse AI's mission is to spearhead an educational
              revolution in India, with an unwavering vision to redefine
              learning by seamlessly integrating artificial intelligence into
              the educational framework.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default DetailStartXSection;
