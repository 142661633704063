import React, { useState } from "react";
import FooterLogo from "../assets/Nav/immverse-logo-tm.svg";
import "../style/footer.scss";
import facebook from "../assets/Footer/facebook.svg";
import linkedin from "../assets/Footer/linkedin.svg";
import twitter from "../assets/Footer/twitterx.svg";
import insta from "../assets/Footer/instagram.svg";
import threads from "../assets/Footer/threads.svg";
import youtube from "../assets/Footer/youtube.svg";
import { Link } from "react-router-dom";

const Footer = (props) => {
  const [hoveredSocial, setHoveredSocial] = useState("");

  const handleMouseEnter = (social) => {
    setHoveredSocial(social);
  };

  const handleMouseLeave = () => {
    setHoveredSocial("");
  };

  const renderQR = (social) => {
    return hoveredSocial === social ? (
      <img
        src={`/qr-svg/${social}.svg`}
        alt={`QR code for ${social}`}
        className="absolute  left-0 bg-white " 
        style={{minWidth:"8rem" ,zIndex:"1"}}
      />
    ) : null;
  };

  return (
    <div className="w-full justify-center items-center flex mt-8 mb-8">
      <div className="footer-section w-3/4 flex">
        <div className="footer-content w-1/2">
          <img src={FooterLogo} alt="" />
          <p className="text-white">Immortalizing Humans</p>
          <div className="social-media">
            <div className="relative">
              <a
                href="https://www.facebook.com/ImmverseAI"
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={() => handleMouseEnter("facebook")}
                onMouseLeave={handleMouseLeave}
                className="facebook-logo"
              >
                <img src={facebook} alt="Facebook" />
              </a>
              {renderQR("facebook")}
            </div>
            <div className="relative">
              <a
                href="https://twitter.com/ImmverseAI"
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={() => handleMouseEnter("twitter")}
                onMouseLeave={handleMouseLeave}
              >
                <img src={twitter} alt="Twitter" />
              </a>
              {renderQR("twitter")}
            </div>
            <div className="relative">
              <a
                href="https://www.instagram.com/immverseai/"
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={() => handleMouseEnter("instagram")}
                onMouseLeave={handleMouseLeave}
              >
                <img src={insta} alt="Instagram" />
              </a>
              {renderQR("instagram")}
            </div>
            <div className="relative">
              <a
                href="https://www.linkedin.com/company/immverse-ai/"
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={() => handleMouseEnter("linkedin")}
                onMouseLeave={handleMouseLeave}
              >
                <img src={linkedin} alt="LinkedIn" />
              </a>
              {renderQR("linkedin")}
            </div>
            {/* <div className="relative">
              <a
                href="https://www.threads.net/@immverseai"
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={() => handleMouseEnter("threads")}
                onMouseLeave={handleMouseLeave}
              >
                <img src={threads} alt="Threads" />
              </a>
              {renderQR("threads")}
            </div> */}
            <div className="relative">
              <a
                href="https://www.youtube.com/@ImmverseAI"
                target="_blank"
                rel="noopener noreferrer"
                onMouseEnter={() => handleMouseEnter("youtube")}
                onMouseLeave={handleMouseLeave}
              >
                <img src={youtube} alt="YouTube" />
              </a>
              {renderQR("youtube")}
            </div>
          </div>
          <p>
            <Link to="/termsOfService">Terms Of Service</Link> |{" "}
            <Link to="/privacyPolicy">Privacy Policy</Link>
          </p>
          <hr />
        </div>

        <div className="footer-content w-1/2">
          <div className="flex w-100 justify-between flex-col flex-md-row gap-4">
            <div className="flex gap-3 flex-col ">
              <p className="text-white font-bold">Navigate</p>
              <Link to="/" className="text-gray-400 hover:scale-105 hover:text-white">
                Home
              </Link>
              <Link to="/about" className="text-gray-400 hover:scale-105 hover:text-white">
                About
              </Link>
              <Link to="https://iks.immverse.ai/" target="_blank" className="text-gray-400 hover:scale-105 hover:text-white">
                IKS
              </Link>
              <Link to="/career" className="text-gray-400 mb-4 hover:scale-105 hover:text-white">
                Careers
              </Link>
            </div>

            <div className="flex gap-3 flex-col">
              <p className="text-white font-bold">Support Us</p>
              <Link to="/contactform" className="text-gray-400 mb-4 hover:scale-105 hover:text-white">
                Contact Us
              </Link>
            </div>

            <div className="flex gap-3 flex-col">
              <p className="text-white font-bold">Resources</p>
              <Link to="/blog" className="text-gray-400 mb-4 hover:scale-105 hover:text-white">
                Blogs
              </Link>
            </div>

            <div className="flex gap-3 flex-col">
              <p className="text-white font-bold">Contact Us</p>
              <a href="mailto:hello@immverse.ai" className="text-gray-400 hover:scale-105 hover:text-white">
                hello@immverse.ai
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
