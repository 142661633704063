import React from 'react'
import Layout from '../components/Layout'
import TeamGallery from '../components/TeamGallery'
import { useEffect } from 'react'

const TeamGalleryPage = () => {
   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);
  return (
   <Layout>
    <TeamGallery/>
   </Layout>
  )
}

export default TeamGalleryPage