import React from 'react'
import Layout from '../components/Layout'
import Career from '../components/Career'
import { useEffect } from 'react'
const CareerPage = () => {
      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <Layout>
            <Career />
        </Layout>
    )
}

export default CareerPage
