import React from "react";
import "../style/engagementeducationcomponent.scss";
import "../assets/rectangle.png";

const EngagementEducationSection = () => {
  //   useEffect(() => {
  //     window.scrollTo(0, 0);
  //   }, []);

  return (
    <>
      <div className="section-engagement-journey ">
        <h1>
          <span>Engagement</span> Journey
        </h1>
        <div className="flex-box">
          <div className="box1">
            <h2 className="head">
              <span>
                MBA <sup>x</sup>
              </span>
              Foundation
            </h2>
            <div className="bullets">
              <h2>LEVEL 1</h2>
              <li>16 sessions</li>
              <li>8 sessions/month</li>
              <li> 2 months /acad year</li>
              <li> 2 Mentor Sessions</li>
            </div>
          </div>
          <div className="box2">
            <h2 className="head">
              <span>
                MBA <sup>x</sup>
              </span>
              Entrepreneurship & Innovation
            </h2>
            <div className="bullets">
              <h2>LEVEL 2</h2>
              <li>24 sessions</li>
              <li> 8 sessions /month</li>
              <li> 3 months /acad year</li>
              <li> 3 Mentor Sessions</li>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EngagementEducationSection;
