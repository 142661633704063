import React from "react";
import "../style/educationcoursebenifits.scss";
import coursebenefits from "../assets/Group 65.png";
import ellipse3 from "../assets/About/ellipse3.svg";

const EducationCourseBenifitsComponent = () => {
  return (
    <div className=" w-[100%] justify-center items-center  md:px-[18%] px-[8%]">
      <div className="ellipse2 absolute top-[110rem] right-0  z-[-1]">
        <img src={ellipse3} alt="" className="w-90" loading="lazy" />
      </div>
      <span className="flex justify-center md:text-4xl font-bold lg:text-5xl text-[35px] mb-16 text-white gap-2">
        <span className="text-pink-600">Course</span>Benefits
      </span>
      <img src={coursebenefits} loading="lazy" />
    </div>
  );
};

export default EducationCourseBenifitsComponent;
