import React, { useState } from "react";

const Dropdown = ({ onSelect }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] =
    useState("Select Designation");

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    console.log("hiii");
  };

  const handleCategoryClick = (selectedValue) => {
    setSelectedCategory(selectedValue);
    console.log("category here");
    onSelect(selectedValue);
    toggleDropdown();
  };

  return (
    <div className="relative inline-block text-left w-100">
      <div>
        <button
          type="button"
          className="inline-flex w-full rounded-lg border-[#333135] bg-[#1e1e1e] text-[#c3c1c7] px-2 py-2 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-[#333135] focus:ring-white min-w-60"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded={isOpen}
          onClick={toggleDropdown}
        >
          {selectedCategory}
        </button>
      </div>

      {isOpen && (
        <div
          className="origin-top-right absolute right-0 mt-2 w-60 ml-20 rounded-md shadow-lg bg-[#333135] ring-1 ring-black ring-opacity-5"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className="py-1" role="none">
            <a
              className="block px-4 py-2 text-sm text-[#c3c1c7] hover:bg-gray-700 hover:text-white"
              role="menuitem"
              onClick={() => handleCategoryClick("Personal Assistant")}
            >
              Personal Assistant
            </a>

            <a
              className="block px-4 py-2 text-sm text-[#c3c1c7] hover:bg-gray-700 hover:text-white"
              role="menuitem"
              onClick={() => handleCategoryClick("Teacher")}
            >
              Teacher
            </a>

            <a
              className="block px-4 py-2 text-sm text-[#c3c1c7] hover:bg-gray-700 hover:text-white"
              role="menuitem"
              onClick={() => handleCategoryClick("Student")}
            >
              Student
            </a>
            <a
              className="block px-4 py-2 text-sm text-[#c3c1c7] hover:bg-gray-700 hover:text-white"
              role="menuitem"
              onClick={() => handleCategoryClick("Founder")}
            >
              Founder
            </a>
            <a
              className="block px-4 py-2 text-sm text-[#c3c1c7] hover:bg-gray-700 hover:text-white"
              role="menuitem"
              onClick={() => handleCategoryClick("Parents")}
            >
              Parents
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
