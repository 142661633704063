import React , {useEffect} from 'react';
import Contactform from '../components/Contactform';
import Layout from '../components/Layout';
import "../style/contactformpage.scss"
import ellipse3 from "../assets/About/ellipse3.svg";

const ContactformPage = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
    return (
        <Layout>
            <div className='contact-deta'>
            <div className='contactform' >
                <Contactform />
            </div>
            </div>
        </Layout>
    );
};

export default ContactformPage;
