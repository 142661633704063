import React from 'react'
import { useEffect } from 'react'
import Layout from '../components/Layout'
import Hero from '../components/Hero'

const Homepage = () => {
   useEffect(() => {
     window.scrollTo(0, 0);
   }, []);
  return (

<>
<Layout>
    <Hero/>
</Layout>
</>
  )
}

export default Homepage