import React, { useState, useEffect } from "react";
import "../style/about.scss";
import mackbook from "../assets/About/mackbook.png";
import ellipse3 from "../assets/About/ellipse3.svg";
import ellipse4 from "../assets/About/ellipse4.svg";
import apple from "../assets/Hero/apple.svg";
import googleplay from "../assets/Hero/googleplay.svg";
import Contactform from "./Contactform";
import { Helmet } from "react-helmet-async";
import AboutShimmerComponent from "./AboutShimmerComponent";
import GalleryLoadingComponent from "./GalleryLoadingcomponent";
import YouTube from "react-youtube";
const AboutComponent = () => {
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoader(false);
  }, []);

  const extractVideoId = (link) => {
    const regex =
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/i;
    const match = link.match(regex);
    return match && match[1];
  };

  const youtubeLink =
    // 'https://www.youtube.com/embed/qPvUVRBKjNU?si=qEFv1XmMv7wG2teM"';
    "https://www.youtube.com/embed/QWzOy0jOaVk";
  const videoId = extractVideoId(youtubeLink);

  const opts = {
    height: "315",
    width: "560",
    playerVars: {
      autoplay: 0,
    },
  };

  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Learn about Immverse AI, the leading innovator in avatar technology. Discover our mission, vision, and commitment to redefining digital identity.
          "
        />
        <meta
          name="keywords"
          content="Immverse, Immverse AI, ImmverseAI,Artificial Intelligence, AI, Personalized AI, Immortalizing
        Humans, Human Preservation, Digital Avatar, Virtual Presence"
        />
        <meta name="author" content="Immverse AI" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.immverseai.ai/" />
        <meta
          property="og:title"
          content="Immverse AI | Immortalizing Humanity through AI"
        />
        <meta property="og:image" content="/immverseLogo.svg" />
        <meta property="twitter:card" content="/immverseLogo.svg" />
        <meta property="twitter:url" content="https://www.immverseai.ai/" />
        <meta
          property="twitter:title"
          content="ImmverseAI - Welcome To ImmverseAI"
        />
        <meta
          property="twitter:description"
          content="Learn about Immverse AI, the leading innovator in avatar technology. Discover our mission, vision, and commitment to redefining digital identity.
          "
        />
        <title>ImmverseAI - About Us</title>
      </Helmet>
      <div
        className="about-container"
        style={{
          backgroundImage: `url(${ellipse3})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top right",
        }}
      >
        <div className="about-content">
          <div className="ellipse4">
            <img src={ellipse4} alt="" width="600" loading="lazy" />
          </div>
          <h1>
            <span>About </span>Us
          </h1>
          <p>
            At ImmverseAI, we're driven by a profound vision — to bridge the gap
            between time and eternity and to provide individuals with the power
            to transcend generations. Our journey began with the aspiration to
            immortalize the essence of humanity, creating a realm where
            knowledge, experiences, and wisdom can thrive eternally.
          </p>
          <p className="solomission">
            "Our Sole Mission Is To Immortalise Humans"
          </p>

          <div className="imgess">
            <a
              href="https://play.google.com/store/apps/details?id=com.immverse.innovation"
              className="googleplay"
              target="_blank"
            >
              <img src={googleplay} alt="" loading="lazy" />
            </a>
            <a
              href="https://apps.apple.com/in/app/immverse/id6468662137"
              className="apple"
              target="_blank"
            >
              <img src={apple} alt="" loading="lazy" />
            </a>
          </div>

          <div className="video">
            {loader ? (
              <AboutShimmerComponent />
            ) : (
              <div className="cover-img">
                <img src={mackbook} alt="" loading="lazy" />

                <YouTube
                  videoId={videoId}
                  opts={opts}
                  onReady={(event) => {
                    event.target.pauseVideo();
                  }}
                  loading="lazy"
                />
              </div>
            )}
          </div>

          <Contactform />
        </div>
        <hr />
      </div>
    </>
  );
};

export default AboutComponent;
