import React from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
// import "../style/blogloading.scss"
import "../style/team-photo-shimmer.scss";

const TeamPhotoLoadingComponent = () => {
  return (
    <div>
      <SkeletonTheme baseColor="#202020" highlightColor="#444">
        <div>
          <div className="flex flex-wrap gap-2 rounded-sm">
            {[...Array(1)].map((x, i) => (
              <p className="text-xl font-bold capitalize job-description-skeleton">
                <Skeleton />
              </p>
            ))}
          </div>
        </div>
      </SkeletonTheme>
    </div>
  );
};

export default TeamPhotoLoadingComponent;
