


import React, { useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import 'react-image-gallery/styles/css/image-gallery.css';
import Gallery from 'react-image-gallery';
import '../style/team-component.scss';

const CloseIcon = ({ onClick }) => (
  <div
    style={{
      position: 'absolute',
      top: 20,
      right: 20,
      cursor: 'pointer',
      zIndex: 1000,
    }}
    onClick={onClick}
  >
    <FaTimes color="white" size={30} />
  </div>
);

const Thumbnail = ({ original, thumbnail, description }) => ({
  original,
  thumbnail,
  description,
});

const MyGalleryComponent = ({ imgUrls, imgCaption, currentImage, closeImageViewer }) => {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(currentImage);

  const handleZoomIn = () => {
    setLightboxIsOpen(true);
  };

  const handleZoomOut = () => {
    setLightboxIsOpen(false);
  };

  const images = imgUrls.map((src, index) => Thumbnail({
    original: src,
    thumbnail: src, // You can use a smaller version for thumbnails if needed
    description: imgCaption[index],
  }));

  return (
    <div className="carousel-image">
      <CloseIcon onClick={closeImageViewer} />
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {/* <button onClick={handleZoomIn}>Zoom In</button> */}
      </div>
      <Gallery
        items={images}
        showFullscreenButton={false}
        showPlayButton={false}
      
        showThumbnails ={false}
        showBullets={false}
        startIndex={currentImage}
        onClickThumbnail={(index) => setPhotoIndex(index)}
      />

      {lightboxIsOpen && (
        <Gallery
          items={images}
          showFullscreenButton
          showPlayButton={false}
          showBullets={false}
          showThumbnails ={false}
          startIndex={photoIndex}
          onClose={handleZoomOut}
        />
      )}
    </div>
  );
};

export default MyGalleryComponent;
