// import React, { useState, useEffect, useRef } from "react";
// import HTMLFlipBook from "react-pageflip";
// import img1 from "../assets/Education/53 (1).png";
// import img2 from "../assets/Education/Avatars Images BG (54).png";
// import img3 from "../assets/Education/Avatars Images BG (54).png";
// import img4 from "../assets/Education/53 (1).png";
// import "./../style/pageflip.scss";

// const PageCover = React.forwardRef((props, ref) => {
//   return (
//     <div className="cover" ref={ref} data-density="hard">
//       <div>
//         <h2>{props.children}</h2>
//       </div>
//     </div>
//   );
// });

// const Page = React.forwardRef((props, ref) => {
//   return (
//     <div className="page" ref={ref}>
//       <h1>Page Header</h1>
//       <p>{props.children}</p>
//       <p>{props.number}</p>
//     </div>
//   );
// });

// function MyAlbum(props) {
//   const [inputText, setInputElement] = useState("");
//   const [text, setText] = useState("Albert Einstein");

//   const [currentPage, setCurrentPage] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       flipToNextPage();
//     }, 1000);

//     return () => clearInterval(interval);
//   }, []);

//   const flipToNextPage = () => {
//     setCurrentPage((prevPage) =>
//       prevPage === pageCount - 1 ? 0 : prevPage + 1
//     );
//   };

//   const pageCount = 6; // Assuming 6 pages in total

//   // Ref to access the HTMLFlipBook instance
//   const flipBookRef = useRef(null);

//   useEffect(() => {
//     // Start auto flipping after 1 second
//     const flipInterval = setInterval(() => {
//       if (flipBookRef.current) {
//         flipBookRef.current.pageFlip();
//       }
//     }, 1000);

//     // return () => {
//     //   clearInterval(flipInterval);
//     // };
//   }, []); // Run effect only once on component mount

//   const printText = () => {
//     setText(inputText);
//     setInputElement("");
//   };

//   return (
//     <div className="mt-20">
//       <div>
//         <HTMLFlipBook
//           width={550}
//           height={650}
//           minWidth={315}
//           maxWidth={1000}
//           minHeight={420}
//           maxHeight={1350}
//           showCover={true}
//           style={{ margin: "0 auto" }}
//           maxShadowOpacity={0.5}
//           className="album-web"
//           flipNext={1000}
//           ref={flipBookRef} // Assign ref to access HTMLFlipBook instance
//           flippingTime={1000}
//           //   style={{ margin: "0 auto" }}
//           // //   maxShadowOpacity={0.5}
//           //   className="album-web"
//           page={currentPage}
//           onChangePage={(e) => setCurrentPage(e.data)}
//         >
//           <PageCover className="text-3xl"></PageCover>
//           <PageCover>
//             {" "}
//             <img src={img1} />
//           </PageCover>
//           <Page number="1">
//             <hr></hr>
//             <p contentEditable="true">Swami Vivekanand</p>
//           </Page>
//           <Page number="2">
//             <hr></hr>
//             <p>{text}</p>
//           </Page>
//           <Page number="3">
//             <hr></hr>

//             <img src={img2} />
//             <p></p>
//           </Page>

//           <Page number="4">
//             <hr></hr>
//             <p>Steve Jobs</p>
//           </Page>
//           <Page number="5">
//             <hr></hr>

//             <img src={img2} />
//             <p></p>
//           </Page>
//           <PageCover>
//             {" "}
//             <img src={img1} />
//           </PageCover>
//           {/* <PageCover>see you</PageCover> */}
//         </HTMLFlipBook>
//         <br></br>
//         <br></br>
//       </div>
//     </div>
//   );
// }

// export default MyAlbum;

// import { FlippingPages } from "flipping-pages";
// import "flipping-pages/dist/style.css";
// import { useState, useEffect } from "react";

// import "../style/pageflip.scss";

// const MyAlbum = () => {
//   const [selected, setSelected] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setSelected((selected) => (selected + 1) % 3); // Assuming you have 3 pages
//     }, 2000); // Change pages every 5 seconds (adjust interval as needed)

//     return () => clearInterval(interval);
//   }, []);

//   return (
//     <div>
//       <div className="pages">
//         <FlippingPages
//           direction="left-to-right"
//           onSwipeEnd={setSelected}
//           selected={selected}
//           animationDuration={1000}
//         >
//           <div className="page page1">Page 1</div>
//           <div className="page page2">Page 2</div>
//           <div className="page page3">Page 3</div>
//         </FlippingPages>
//       </div>
//     </div>
//   );
// };

// export default MyAlbum;

import { FlippingPages } from "flipping-pages";
import "flipping-pages/dist/style.css";
import { useState, useEffect } from "react";
import img4 from "../assets/Education/Steve-jobs.png";
import img3 from "../assets/Education/CV-raman.png";
import img2 from "../assets/Education/albert-einstein.png"; // Add your third image import
import img1 from "../assets/Education/swami-vivekanand.png"; // Add your fourth image import

import "../style/pageflip.scss";

const MyAlbum = () => {
  const [selected, setSelected] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSelected((selected) => (selected + 1) % 4);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  const pages = [
    {
      title: "Swami Vivekananda",
      image: img1,
    },
    {
      title: "Albert Einstein",
      image: img2,
    },
    {
      title: "C. V. Raman",
      image: img3,
    },
    {
      title: "Steve Jobs",
      image: img4,
    },
  ];

  return (
    <div className="w-full flex items-center justify-center">
      <div className="pages">
        <FlippingPages
          direction="left-to-right"
          onSwipeEnd={setSelected}
          selected={selected}
          animationDuration={1000}
        >
          {pages.map((page, index) => (
            <div key={index} className={`page page${index + 1}`}>
              <div className="flex">
                <div className="w-1/2">
                  {" "}
                  <h1 className="text-[#d71d89] mt-20 sm:mt-24 md:mt-36 xl:mt-40 text-sm md:text-3xl lg:text-4xl font-bold flex items-center justify-center">
                    {" "}
                    BE THE NEXT :
                  </h1>
                  <h1 className="text-white animated-text text-sm md:text-3xl lg:text-4xl  font-bold flex items-center justify-center">
                    {page.title}
                  </h1>
                </div>

                <img
                  className="w-1/2 h-[12rem] sm:h-[16rem] md:h-[25rem] lg:h-[28rem] xl:h-[28rem] pt-2 pr-2 pb-2 p-2"
                  src={page.image}
                />
              </div>
            </div>
          ))}
        </FlippingPages>
      </div>
    </div>
  );
};

export default MyAlbum;
