import React, { useEffect, useState, useRef } from "react";
import "../style/career.scss";
import ellipse3 from "../assets/About/ellipse3.svg";
import ellipse4 from "../assets/About/ellipse4.svg";
import job from "../assets/Career/job.svg";
import watch from "../assets/Career/watch.svg";
import location from "../assets/Career/location.svg";
import axios from "axios";
import { GrFormClose } from "react-icons/gr";
import { domainData } from "./Domain";
import mackbook from "../assets/About/mackbook.png";
import { FaPlay, FaPause } from "react-icons/fa";
import teamphoto from "../assets/Career/immverse.png";
import Select from "react-select";
import {
  Button,
  Dialog,
  DialogTrigger,
  Heading,
  Input,
  Label,
  Modal,
  TextField,
} from "react-aria-components";
import Contactform from "./Contactform";
import { Helmet } from "react-helmet-async";
import JobDescription from "./JobDescription";
import { useNavigate } from "react-router-dom";
// import CareerLoadingComponent from "./Careerloadingcomponent";
import CareerLoadingComponent from "./Careerloadingcomponent";
import CareerDetailComponent from "./CareerDetailComponent";
// import bannerimage from "../assets/Career/Gauri-nanika-fashion-designers-Delhi-farmhouse-2.jpg"
import bannerimage from "../assets/Career/teamphoto.png";
import youtubevideo from "../assets/Career/youtube_why-join (2).mp4";
import HoverVideoPlayer from "react-hover-video-player";
import SliderComponent from "./slidercomponent";
import CardSlider from "./slidercomponent";
import Card from "./slidercomponent";
import TeamPhotoLoadingComponent from "./Teamphoto-shimmer";

import CareerImageGalleryComponent from "./CareerImageGalleryComponent";

const Career = (props) => {
  // const videoRef = useRef(null);

  const videoRef = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);
  const [userInteracted, setUserInteracted] = useState(false);

  const handleVideoClick = () => {
    setUserInteracted(true);

    if (!isPlaying) {
      videoRef.current.play().catch((error) => {
        console.error("Failed to play the video:", error.message);
      });
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  // const handleScroll = () => {
  //   if (!userInteracted) {
  //     return;
  //   }

  //   const scrollY = window.scrollY || document.documentElement.scrollTop;
  //   const threshold = 0;

  //   if (scrollY > threshold && isPlaying) {
  //     videoRef.current.pause();
  //     setIsPlaying(false);
  //   } else if (scrollY <= threshold && !isPlaying) {
  //     videoRef.current.play().catch((error) => {
  //       console.error("Failed to play the video:", error.message);
  //     });
  //     setIsPlaying(true);
  //   }
  // };

  // useEffect(() => {
  //   const handleShadow = () => {
  //     if (window.pageYOffset >= 20 && isPlaying == true) {
  //       videoRef.current.pause();
  //       console.log("hi");
  //     }
  //   };
  //   window.addEventListener("scroll", handleShadow, true);
  //   return window.removeEventListener("scroll", handleShadow);
  // }, []);

  const handleScroll = () => {
    if (!userInteracted) {
      return;
    }

    const scrollY = window.scrollY || document.documentElement.scrollTop;
    const threshold = 0;

    if (scrollY > threshold) {
      videoRef.current.pause();
      console.log("i m here");
      // setIsPlaying(false);
    } else if (scrollY <= threshold) {
      videoRef.current.play().catch((error) => {
        console.error("Failed to play the video:", error.message);
      });
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [userInteracted]);

  const [showAllJobs, setShowAllJobs] = useState(false);

  const jobContainerRef = useRef();

  const navigate = useNavigate();

  const [isJobsClass, setIsJobsClass] = React.useState(false);
  const section1Ref = useRef();

  // const cards = [1, 2, 3, 4, 5, 6, 7, 8];

  const [slideIndex, setSlideIndex] = useState(0);

  const nextSlide = () => {
    setSlideIndex((prevIndex) => (prevIndex + 1) % numberOfCards);
  };

  const prevSlide = () => {
    setSlideIndex(
      (prevIndex) => (prevIndex - 1 + numberOfCards) % numberOfCards
    );
  };

  const numberOfCards = 8; // Adjust the number of cards as needed
  const cardsToShow = 3;
  // const toggleJobsClass = () => {
  //   setIsJobsClass(!isJobsClass);

  //   const jobContainer = document.getElementById("job-container-content");

  //   if (jobContainer) {
  //     jobContainer.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  const toggleJobsClass = () => {
    setIsJobsClass(!isJobsClass);

    const jobContainer = document.getElementById("job-container-content");

    if (jobContainer) {
      // Get the bounding client rect of the element
      const rect = jobContainer.getBoundingClientRect();

      const margin =
        5 * parseFloat(getComputedStyle(document.documentElement).fontSize);

      window.scrollTo({
        top: window.scrollY + rect.top - margin,
        behavior: "smooth",
      });
    }
  };

  const openLink = () => {
    const youtubeLink =
      "https://recruit.zoho.in/recruit/immverseai/ShowTab.do?module=Home&submodule=Home";
    window.open(youtubeLink, "_blank");
  };

  const [loader, setLoader] = useState(true);

  const ref = useRef(null);
  const { onClickOutside } = props;

  // const handleTogglePlay = () => {
  //   if (videoRef.current) {
  //     const newIsPlaying = !videoRef.current.paused;
  //     setIsPlaying(newIsPlaying);
  //     if (newIsPlaying) {
  //       videoRef.current.pause();
  //     } else {
  //       videoRef.current.play();
  //     }
  //   }
  // };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [onClickOutside]);

  const openJobDescription = (jobId) => {
    navigate(`./JobDescription/${jobId}`);
  };

  const [selectedJob, setIsJobOpen] = useState(false);
  const [selectedLocation, setIsLocationOpen] = useState(false);

  const handleSelectLocation = (selectedLocation) => {
    setIsLocationOpen(false);
    setFormData({
      ...formdata,
      location: selectedLocation.id,
    });
  };

  const handleSelectJob = (selectedJob) => {
    setIsJobOpen(false);
    setFormData({
      ...formdata,
      job: selectedJob.id,
    });
  };

  const [data, setData] = useState();
  const [locations, setLocations] = useState([]);

  const [engineeringdata, setEngineeringData] = useState();
  const [jobs, setJobs] = useState([]);
  const [formdata, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    resumeurl: "",
    portfoliourl: "",
  });

  const [resume, setResume] = useState({});

  const [searchTerm, setSearchTerm] = useState("");
  const [message, setMessage] = useState("");

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
  };

  const handlechangeforResume = (e) => {
    const file = e.target.files[0];

    setResume(file);
  };

  const handleSubmit = async (e, jobId) => {
    e.preventDefault();

    let formData = new FormData();

    formData.append("candidate_name", formdata.name);
    formData.append("email", formdata.email);
    formData.append("mobile_number", formdata.mobile);
    formData.append("resume", resume);
    formData.append("resume_url", formdata.resumeurl);
    formData.append("portfolio_url", formdata.portfoliourl);
    formData.append("job_id", jobId);

    // Create Axios config
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `${domainData.url}/submit-resume/`,
      headers: {
        "X-Api-Key": "Immverse-API-sam-0987654321",
      },
      data: formData,
    };

    // Send the POST request
    await axios
      .request(config)
      .then((response) => {
        setLoader(false);
        alert(response.data.success);

        window.location.reload(true);
      })
      .catch((error) => {});
  };

  useEffect(() => {
    const getData = async () => {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${domainData.url}/get-all-career`,
        headers: {
          "x-api-key": "Immverse-API-sam-0987654321",
        },
      };

      await axios
        .request(config)
        .then((response) => {
          setData(response.data);

          const engineeringdata = response.data.careers.data.map(
            (item) => item.department_id
          );

          setLoader(false);
          const locationsData = [];
          for (const item of response.data.careerCountryData) {
            locationsData.push({
              id: item.country_code,
              name: item.country_name,
            });
          }
          setLocations(locationsData);
          const jobsData = [];
          for (const job of response.data.employmentTypeData) {
            jobsData.push({
              id: job.id,
              employment_type: job.employment_type,
            });
          }
          setJobs(jobsData);
          setLoader(false);
        })
        .catch((error) => {
          throw new error();
        });
    };
    getData();
  }, []);

  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [isJobsAvailable, setIsJobsAvailable] = useState(true);

  const toggleDepartment = (departmentId) => {
    if (selectedDepartment === departmentId) {
      if (showAllJobs) {
        setSelectedDepartment(null);
        setJobs(data?.careers?.data);
        setShowAllJobs(false);
        const jobContainer = document.getElementById(
          "job-container-content-roles"
        );
        if (jobContainer) {
          jobContainer.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        setShowAllJobs(true);
      }
    } else {
      setSelectedDepartment(departmentId);
      setShowAllJobs(false);
      const departmentJobsData = data?.careers?.data?.filter(
        (item) => item.department_id === departmentId
      );
      setIsJobsAvailable(departmentJobsData.length > 0);
      setJobs(departmentJobsData);
    }
  };

  useEffect(() => {
    if (!selectedDepartment) {
      setJobs(data?.careers?.data);
    } else {
      const departmentJobsData = data?.careers?.data?.filter(
        (item) => item.department_id === selectedDepartment
      );
      setJobs(departmentJobsData);
    }
  }, [selectedDepartment, data]);

  const searchData = async () => {
    try {
      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${domainData.url}/get-all-career?search=${searchTerm}`,
        headers: {
          "x-api-key": "Immverse-API-sam-0987654321",
        },
        params: {
          search: searchTerm,
          jobType: jobs,
          location: location,
        },
      };

      const response = await axios.request(config);
      console.log("Response", response.data);

      if (response.data.careers.data.length === 0) {
        setData([]);
        setMessage("No data found");
      } else {
        setData(response.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // const searchData = async () => {
  //   let config = {
  //     method: "get",
  //     maxBodyLength: Infinity,
  //     url: `${domainData.url}/get-all-career?search=${searchTerm}`,
  //     headers: {
  //       "x-api-key": "Immverse-API-sam-0987654321",
  //     },
  //     params: {
  //       search: searchTerm,
  //       jobType: jobs,
  //       location: location,
  //     }
  //   };

  //   await axios
  //     .request(config)
  //     .then((response) => {
  //    console.log("responsee",response)
  //       setData(response.data);
  //     })
  //     .catch((error) => {

  //     });
  // };
  return (
    <>
      <Helmet>
        <meta
          name="description"
          content="Explore career opportunities at Immverse AI and join our team of visionaries. Shape the future of avatar technology and digital interaction.
          "
        />
        <meta
          name="keywords"
          content="Immverse, Immverse AI, ImmverseAI,Artificial Intelligence, AI, Personalized AI, Immortalizing
        Humans, Human Preservation, Digital Avatar, Virtual Presence"
        />
        <meta name="author" content="Immverse AI" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.immverseai.ai/" />
        <meta
          property="og:title"
          content="Immverse AI | Immortalizing Humanity through AI"
        />
        <meta property="og:image" content="/immverseLogo.svg" />
        <meta property="twitter:card" content="/immverseLogo.svg" />
        <meta property="twitter:url" content="https://www.immverseai.ai/" />
        <meta
          property="twitter:title"
          content="ImmverseAI - Welcome To ImmverseAI"
        />
        <meta
          property="twitter:description"
          content="Explore career opportunities at Immverse AI and join our team of visionaries. Shape the future of avatar technology and digital interaction.
          "
        />
        <title>ImmverseAI - Career</title>
      </Helmet>
      <div
        className="career-container"
        ref={ref}
        style={{
          backgroundImage: `url(${ellipse3})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top right",
        }}
      >
        <div className="career-content">
          <div className="ellipse4">
            <img src={ellipse4} alt="" width="600" />
          </div>

          {/* <div className="search-blog">
            <input
              className="input1"
              type="text"
              id="keyword"
              name="keywork"
              placeholder="Enter your keyword"
              onChange={(e) => setSearchTerm(e.target.value)}
              
            />




    

    <div className="custom-dropdown">
  <Select
    value={locations.find(location => location.value === selectedLocation)}
    onChange={handleSelectLocation}
    options={locations.map(location => ({ value: location.id, label: location.name }))}
    placeholder="Select Location"
    styles={{
      control: (provided, state) => ({
        ...provided,
        height: '50px',
        borderRadius: '40px',
        border: '2px solid #333135',
        width: '180px',
        backgroundColor: 'rgba(22, 22, 22, 0.2)',
        outline: 'none',
        boxShadow: state.isFocused ? 0 : 0,
        color: ' #d4d1db',
        ':hover': {
          border: '2px solid #333135',
        },
        ':focus': {
          border: 'none',
          outline: 'none',
          boxShadow: '0 0 0 2px #333135',
        },
      }),
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? ' #d4d1db' : ' #d4d1db',
        backgroundColor: state.isSelected ? '#333135' : 'transparent',
        ':hover': {
          backgroundColor: '#333135',
          color: ' #d4d1db',
        },
      }),
      menu: provided => ({
        ...provided,
        backgroundColor: 'rgba(22, 22, 22, 0.5)',
      }),
      singleValue: provided => ({
        ...provided,
        color: ' #d4d1db',
      }),
      indicatorSeparator: provided => ({
        ...provided,
        backgroundColor: 'transparent',
      }),
      indicatorContainer: provided => ({
        ...provided,
        display: 'none',
      }),
    }}
  />
 
</div>


    




    
     




<div className="custom-dropdown-job">
  <Select
    value={jobs.find(job => job.value === selectedJob)}
    onChange={handleSelectJob}
    options={jobs.map(job => ({ value: job.id, label: job.employment_type }))}
    placeholder="Select Job Type"
    styles={{
      control: (provided,state) => ({
        ...provided,
        height: '50px',
        borderRadius: '40px',
        border: '2px solid #333135',
        width: '180px',
        backgroundColor: 'rgba(22, 22, 22, 0.2)',
        outline:'none',

     
    boxShadow: state.isFocused ? 0 : 0,
    
        color: ' #d4d1db',
        ':hover': {
          border: '2px solid #333135', 
        },
        ':focus': {
          border: 'none',
          outline: 'none', 
          boxShadow: '0 0 0 2px #333135', 
        },
      }),
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? ' #d4d1db' : ' #d4d1db',
        backgroundColor: state.isSelected ? 'rgba(22, 22, 22, 0.2)' : 'transparent',
        ':hover': {
          backgroundColor: '#333135',
          color: ' #d4d1db',
        },
      }),
      menu: provided => ({
        ...provided,
        backgroundColor: 'rgba(22, 22, 22, 0.5)', 
      }),
      singleValue: provided => ({
        ...provided,
        color: ' #d4d1db', 
      }),
      indicatorSeparator: provided => ({
        ...provided,
        backgroundColor: 'transparent', 
      }),
    }}
  />
</div>


            

            <div className="btn-position">
              <button onClick={() => searchData()}>Search</button>
            </div>
           
          </div> */}

          {/* <div className="section-1">


<div className="section-text">
 <h1>Create Positive Impact</h1>

 <button>Explore Roles</button>
</div>
<div className="banner-image">
 
 <img src="https://www.johnnynash.coach/wp-content/uploads/2021/02/Career-Image-2048x1365.jpg"></img>


</div>



</div> */}

          <div
            ref={section1Ref}
            className={`section-1 ${
              isJobsClass ? "job-container-content" : ""
            }`}
          >
            <div className="section-text">
              <h3>
                <span>Elevate</span> your AI game with<br></br> ImmverseAI
              </h3>
              {/* <h4>We are an innovative tech venture in a fast moving space.</h4>
              <h4>
                ImmverseAI introduces a unique dimension to career roles by
                offering professionals the opportunity to immortalize their
                expertise and insights.
              </h4>

              <h4>
                ImmverseAI immortalizes careers, preserving expertise through
                AI.
              </h4> */}

              <p>
                We are an innovative tech venture in a fast-moving space.
                ImmverseAI introduces a unique dimension to career roles by
                offering professionals the opportunity to immortalize their
                expertise and insights. ImmverseAI immortalizes careers,
                preserving expertise through AI.
              </p>
              <button className="hover:scale-110" onClick={toggleJobsClass}>
                Explore Roles
              </button>
            </div>
            <div className="banner-image">
              {loader ? (
                <TeamPhotoLoadingComponent />
              ) : (
                <img src={bannerimage} alt="Banner Image" loading="lazy" />
              )}
            </div>
          </div>

          <div className="section-join">
            {/* <div className="cover-img">
            <img src={mackbook}></img>
            <HoverVideoPlayer
              <div
                style={{ position: "relative", width: "100%", height: "100%" }}
              >
                <iframe
                  ref={videoRef}
                  className="w-full h-full rounded-md"
                  autoPlay={isPlaying}
                  muted={false}
                  loop={true} // Add this line if you want the video to loop
                  // Add your video source here
                  src={youtubevideo}
                  style={{ borderRadius: "18px" }}
                />
                <button
                  onClick={handleTogglePlay}
                  style={{
                    color: "#c3c1c7",
                    position: "absolute",
                    top: "70%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    border: "2px solid #333135",
                    borderRadius: "10px",
                    padding: "0.5rem",
                    backgroundColor: "transparent",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {isPlaying ? <FaPause /> : <FaPlay />}
                </button>
              </div>
            </div> */}
            {/* 
            <div className="cover-img">
              <video
                ref={videoRef}
                width="100%"
                height="100%"
                controls
                autoPlay={false}
                onClick={handleVideoClick}
              >
                <source src={youtubevideo} type="video/mp4" />
                Your browser does not support the video
              </video>
            </div> */}

            <div className="cover-img">
              <video
                ref={videoRef}
                width="100%"
                height="100%"
                controls
                autoPlay={false}
                onClick={handleVideoClick}
                loading="lazy"
              >
                <source src={youtubevideo} type="video/mp4" />
                Your browser does not support the video
              </video>
            </div>

            <div className="text-section">
              <h3>
                <span>Why Join</span> ImmverseAI
              </h3>
              <p>
                Joining Immverse AI means being part of a pioneering team that
                is shaping the future of artificial intelligence. As a part of
                this mission, you will be working on a cutting-edge tech stack.
                You will be at the forefront of AI evolution, building products
                for the world, which will be first to the world.
              </p>
            </div>
          </div>

          <div className="section-third">
            <h3>
              <span
                style={{
                  color: "#d71d89",

                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                Thrive{" "}
              </span>{" "}
              in Collaboration
            </h3>
            <p>
              Become a part of our thriving AI community! Collaborate, innovate,
              and grow with fellow contributors, shaping the future of
              artificial intelligence.
            </p>
            {/* <img src={teamphoto} alt="Banner Image" loading="lazy" /> */}

            <CareerImageGalleryComponent />

            {/* <div className="collage">
              <div className="image-flex">
                <img src={img1} alt="Banner Image" loading="lazy" />
                <img src={img2} alt="Banner Image" loading="lazy" />
              </div>
              <div className="image-flex2">
                <img src={img3} alt="Banner Image" loading="lazy" />
                <img src={img4} alt="Banner Image" loading="lazy" />
              </div>
            </div> */}
          </div>

          {message && <p className="error-message">{message}</p>}

          <div className="slider-container">
            <Card />
          </div>

          <div className="jobs">
            {/* <div className="search-blog">
            <input
              className="input1"
              type="text"
              id="keyword"
              name="keywork"
              placeholder="Enter your keyword"
              onChange={(e) => setSearchTerm(e.target.value)}
              
            />




    

    <div className="custom-dropdown">
  <Select
    value={locations.find(location => location.value === selectedLocation)}
    onChange={handleSelectLocation}
    options={locations.map(location => ({ value: location.id, label: location.name }))}
    placeholder="Select Location"
    styles={{
      control: (provided, state) => ({
        ...provided,
        height: '50px',
        borderRadius: '40px',
        border: '2px solid #333135',
        width: '180px',
        backgroundColor: 'rgba(22, 22, 22, 0.2)',
        outline: 'none',
        boxShadow: state.isFocused ? 0 : 0,
        color: ' #d4d1db',
        ':hover': {
          border: '2px solid #333135',
        },
        ':focus': {
          border: 'none',
          outline: 'none',
          boxShadow: '0 0 0 2px #333135',
        },
      }),
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? ' #d4d1db' : ' #d4d1db',
        backgroundColor: state.isSelected ? '#333135' : 'transparent',
        ':hover': {
          backgroundColor: '#333135',
          color: ' #d4d1db',
        },
      }),
      menu: provided => ({
        ...provided,
        backgroundColor: 'rgba(22, 22, 22, 0.5)',
      }),
      singleValue: provided => ({
        ...provided,
        color: ' #d4d1db',
      }),
      indicatorSeparator: provided => ({
        ...provided,
        backgroundColor: 'transparent',
      }),
      indicatorContainer: provided => ({
        ...provided,
        display: 'none',
      }),
    }}
  />
 
</div>


    




    
     




<div className="custom-dropdown-job">
  <Select
    value={jobs.find(job => job.value === selectedJob)}
    onChange={handleSelectJob}
    options={jobs.map(job => ({ value: job.id, label: job.employment_type }))}
    placeholder="Select Job Type"
    styles={{
      control: (provided,state) => ({
        ...provided,
        height: '50px',
        borderRadius: '40px',
        border: '2px solid #333135',
        width: '180px',
        backgroundColor: 'rgba(22, 22, 22, 0.2)',
        outline:'none',

     
    boxShadow: state.isFocused ? 0 : 0,
    
        color: ' #d4d1db',
        ':hover': {
          border: '2px solid #333135', 
        },
        ':focus': {
          border: 'none',
          outline: 'none', 
          boxShadow: '0 0 0 2px #333135', 
        },
      }),
      option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? ' #d4d1db' : ' #d4d1db',
        backgroundColor: state.isSelected ? 'rgba(22, 22, 22, 0.2)' : 'transparent',
        ':hover': {
          backgroundColor: '#333135',
          color: ' #d4d1db',
        },
      }),
      menu: provided => ({
        ...provided,
        backgroundColor: 'rgba(22, 22, 22, 0.5)', 
      }),
      singleValue: provided => ({
        ...provided,
        color: ' #d4d1db', 
      }),
      indicatorSeparator: provided => ({
        ...provided,
        backgroundColor: 'transparent', 
      }),
    }}
  />
</div>


            

            <div className="btn-position">
              <button onClick={() => searchData()}>Search</button>
            </div>
           
          </div> */}

            <div id="job-container-content" ref={jobContainerRef}>
              {" "}
              <h1>
                <span
                  style={{
                    color: "#d71d89",

                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Current{" "}
                </span>{" "}
                Openings
              </h1>
            </div>

            <div className="app-container">
              <div
                className={`department-column ${
                  selectedDepartment === 1 ? "active" : ""
                }`}
                onClick={() => toggleDepartment(1)}
              >
                <h2>Engineering</h2>
              </div>
              <div
                className={`department-column ${
                  selectedDepartment === 2 ? "active" : ""
                }`}
                onClick={() => toggleDepartment(2)}
              >
                <h2>Product</h2>
              </div>
              <div
                className={`department-column ${
                  selectedDepartment === 3 ? "active" : ""
                }`}
                onClick={() => toggleDepartment(3)}
              >
                <h2>HR and Admin</h2>
              </div>
              <div
                className={`department-column ${
                  selectedDepartment === 5 ? "active" : ""
                }`}
                onClick={() => toggleDepartment(5)}
              >
                <h2>Sales and Marketing</h2>
              </div>
              <div
                className={`department-column ${
                  selectedDepartment === 6 ? "active" : ""
                }`}
                id="internship-col"
                onClick={() => toggleDepartment(6)}
              >
                <h2>Internship</h2>
              </div>
            </div>
            {!isJobsAvailable && (
              <p className="error-message">No jobs available</p>
            )}

            {loader ? (
              <CareerLoadingComponent />
            ) : (
              jobs?.map((post) => (
                <div key={post.id} className="job-container">
                  <div className="job-description">
                    <div
                      className="Ai"
                      onClick={() => openJobDescription(post.id)}
                    >
                      <div className="svg-bg">
                        <img src={job} alt="" />
                      </div>
                      <p className="text-[#c1c7c6]">
                        <span>{post.job_title}</span>
                      </p>
                    </div>
                    <div className="locationwatch">
                      <div className="location">
                        <img src={watch} alt="" />
                        <p>{post.region?.region_name}</p>
                      </div>
                      <div className="watch">
                        <img src={location} alt="" />
                        <p>Full time</p>
                      </div>
                    </div>
                    <h6>Job Description:</h6>
                    <p>
                      {post.job_description ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: post.job_description.replace(
                              /<br\s*\/?>/g,
                              " "
                            ),
                          }}
                        />
                      ) : (
                        "No job description available"
                      )}
                    </p>
                  </div>
                </div>
              ))
            )}
          </div>
        </div>
        <div className="contact-section">
          {" "}
          <Contactform />
        </div>

        <hr />
      </div>
    </>
  );
};

export default Career;
