import React, { useState, useEffect } from "react";
import { Routes, Route, HashRouter, Navigate } from "react-router-dom";
import Homepage from "./pages/Homepage";
import About from "./pages/About";
import BlogPage from "./pages/BlogPage";
import TeamGalleryPage from "./pages/TeamGalleryPage";
import BlogDetailPage from "./pages/BlogDetailPage";
import CareerPage from "./pages/CareerPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsOfServicePage from "./pages/TermsOfServicePage";
import JobDescription from "./components/JobDescription";
import ContactformPage from "./pages/ContactformPage";
import CookieConsent from "react-cookie-consent";
import Educationpage from "./pages/EducationPage";
import FellowshipPage from "./pages/FellowshipPage";
import FellowshipForm from "./components/FellowShip/FellowshipAlanTuringFormComponent";
import RichardFellowshipForm from "./components/FellowShip/FellowshipRichardformComponent";
import ReactPixel from "react-facebook-pixel";
import TwitterPixel from "react-twitter-pixel";

const App = () => {
  useEffect(() => {
    ReactPixel.init("https://www.facebook.com/ImmverseAI");
    ReactPixel.pageView();

    TwitterPixel.init("https://twitter.com/ImmverseAI");
    TwitterPixel.pageView();
  }, []);

  return (
    <HashRouter>
      <div>
        <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#313135", fontSize: "11px" }}
          buttonStyle={{
            color: "#fffff",
            fontSize: "11px",
            background: "#000000",
          }}
          expires={150}
        >
          This website uses cookies to enhance the user experience.{" "}
        </CookieConsent>
        <meta
          name="google-site-verification"
          content="fxm420grF2HHp9UWzz0u5F8M1z_LfhDUZCiFuCK1ZGM"
        />
        <div>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/about" element={<About />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/teamGallery" element={<TeamGalleryPage />} />
            <Route path="/blogdetails/:id" element={<BlogDetailPage />} />
            <Route path="/career" element={<CareerPage />} />

            <Route
              path="/career/JobDescription/:jobId"
              element={<JobDescription />}
            />
            <Route path="/privacyPolicy" element={<PrivacyPolicyPage />} />
            <Route path="/termsOfService" element={<TermsOfServicePage />} />
            <Route path="/contactform" element={<ContactformPage />} />
            <Route path="/education" element={<Educationpage />} />
            <Route path="/fellowship" element={<FellowshipPage />} />
            {/* <Route path="/fellowship/apply" element={<FellowshipForm />} /> */}
            <Route
              path="/fellowship/apply/Alan-Turing-fellowship"
              element={<FellowshipForm />}
            />
            <Route
              path="/fellowship/apply/Richard-Feynman-fellowship"
              element={<RichardFellowshipForm />}
            />
          </Routes>
        </div>
      </div>
    </HashRouter>
  );
};

export default App;
